import React, { useEffect, useState } from 'react';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import Button from '@mui/material/Button';
import { loadAllDecks, loadDeck, deleteDeck } from '../util';

const SavedDecks = ({ setSelectedCardType, setSelectedCards, setSortedSelectedCards, onEnter, handleExportButtonClick }) => {
    const [decks, setDecks] = useState({});

    useEffect(() => {
        setDecks(loadAllDecks());
    }, []);

    const onBack = () => {
        setSelectedCardType('');
        onEnter('landing');
    };

    const handleLoadDeck = (deckId) => {
        const selectedDeck = loadDeck(deckId);
        if (selectedDeck) {
            setSelectedCardType(selectedDeck.selectedCardType);
            setSelectedCards(selectedDeck.cards);
            setSortedSelectedCards(selectedDeck.cards);
            onEnter('deckBuilder');
        } else {
            console.error(`Deck ${deckId} not found!`);
        }
    };

    const handleDeleteDeck = async (deckId) => {
        if (window.confirm('Are you sure you want to delete this deck? This action cannot be undone.')) {
            await deleteDeck(deckId); // Ensure deletion is complete
            const updatedDecks = await loadAllDecks(); // Wait for new deck list
            setDecks(updatedDecks);
        }
    };



    return (
        <div className="container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className="landing-page" style={{ color: '#DADADA', textAlign: 'center' }}>
                <h1>Your Saved Decks</h1>
                <p>Select a deck to view or manage</p>
            </div>
            <Box
                component="ul"
                sx={{
                    display: 'flex',
                    gap: 2,
                    flexWrap: 'wrap',
                    p: 0,
                    m: 0,
                    justifyContent: 'center',
                    width: '75%',
                }}
            >
                {Object.keys(decks).length === 0 ? (
                    <p style={{ color: 'white', fontStyle: 'italic' }}>No saved decks found!</p>
                ) : (
                    Object.entries(decks).map(([deckId, deck]) => {
                        console.log(deck);
                        const firstCardImage = `/card_images/${deck.selectedCardType}/${deck.cards[0]?.Key}.webp` || '/assets/LandingComingSoon.png';

                        return (
                            <Card
                                key={deckId}
                                component="li"
                                sx={{
                                    flexBasis: 'calc(33% - 16px)',
                                    minWidth: 0,
                                    height: '100%',
                                    '&:hover': {
                                        border: '3px solid #1E1F21',
                                        filter: 'brightness(85%)',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                        cursor: 'pointer',
                                    },
                                }}
                            >
                                <CardCover>
                                    <img
                                        src={firstCardImage}
                                        alt={`Deck ${deckId}`}
                                        loading="lazy"
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = '/assets/LandingComingSoon.png';
                                        }}
                                        style={{
                                            position: 'absolute',
                                            objectFit: 'cover',
                                            objectPosition: '50% 15%',
                                        }}
                                    />
                                </CardCover>
                                <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <Typography
                                        level="h5"
                                        fontWeight="lg"
                                        textColor="#fff"
                                        sx={{
                                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                            padding: '8px 12px',
                                            borderRadius: '4px',
                                            marginTop: '8px',
                                        }}
                                    >
                                        {deck.deckName}
                                    </Typography>
                                    <Box sx={{ display: 'flex', gap: 1, marginTop: 'auto' }}>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            color="primary"
                                            onClick={() => handleLoadDeck(deckId)}
                                        >
                                            Load
                                        </Button>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            color="error"
                                            onClick={() => handleDeleteDeck(deckId)}
                                        >
                                            Delete
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        );
                    })
                )}
            </Box>
            <Button
                variant="text"
                onClick={onBack}
                sx={{
                    color: '#DADADA',
                    textDecoration: 'underline',
                    marginTop: '20px',
                }}
            >
                Back to Game Selection
            </Button>
        </div>
    );
};

export default SavedDecks;
