import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, SvgIcon } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import './CardModal.css';
import { ArrowForwardIos } from '@mui/icons-material';

const CardModal = ({ showModal, selectedModalCard, handleAAButton, handleFlipButton, selectedCardType, isFullScreen, handleClose, handleNextCard, handlePreviousCard, isSmallScreen }) => {
    const [viewMode, setViewMode] = useState('card-and-text'); // Default to 'card-and-text'    
    const renderCardInformation = () => {
        if (!selectedModalCard) return null;

        switch (selectedCardType) {
            case "optcg":
                return (
                    <div className='cardInformation'>
                        <div className="cardInformationName">{selectedModalCard['Card Name']}</div>
                        <div className="cardInformationNumber">{selectedModalCard["Set"]}</div>
                        <div className="cardInformationNumber">{selectedModalCard['CARD ID'][0]}-{selectedModalCard['CARD ID'][1]} | {selectedModalCard["Rarity"]}</div>
                        {selectedModalCard['Effect'] !== '-' && selectedModalCard['Effect'] !== "" &&
                            <div className="cardInformationEffect"><span className="cardInformationLabel">Effect: </span> {selectedModalCard['Effect']}</div>
                        }
                        {selectedModalCard['Trigger'] &&
                            <div className="cardInformationEffect"> <span className="cardInformationLabel">Trigger: </span>{selectedModalCard['Trigger']}</div>
                        }
                        <div className="cardBullets">
                            {selectedModalCard['Category'] && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Category:</span> {selectedModalCard['Category']}</li>
                                </ul>
                            )}
                            {selectedModalCard['Color'] && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Color:</span> {selectedModalCard['Color'] && selectedModalCard['Color'].join(', ')}</li>
                                </ul>
                            )}
                            {selectedModalCard['Type'] && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Type:</span> {selectedModalCard['Type'] && selectedModalCard['Type'].join(', ')}</li>
                                </ul>
                            )}
                            {selectedModalCard['Cost'] && selectedModalCard['Category'] !== 'Leader' && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Cost:</span> {selectedModalCard['Cost'] && selectedModalCard['Cost']['Generic']}</li>
                                </ul>
                            )}
                            {selectedModalCard['Power'] > 0 && (selectedModalCard['Category'] !== 'Event' || selectedModalCard['Category'] !== 'Stage') && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Power:</span> {selectedModalCard['Power']}</li>
                                </ul>
                            )}
                            {selectedModalCard['Counter'] > 0 && (selectedModalCard['Category'] !== 'Event' || selectedModalCard['Category'] !== 'Stage') && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Counter:</span> {selectedModalCard['Counter']}</li>
                                </ul>
                            )}
                        </div>
                    </div>
                );
            case "fusionworld":
                return (
                    <div className='cardInformation'>
                        <div className="cardInformationName">{selectedModalCard['Card Name']}</div>
                        <div className="cardInformationNumber">{selectedModalCard["Set"]}</div>
                        <div className="cardInformationNumber">{selectedModalCard['CARD ID'][0]}-{selectedModalCard['CARD ID'][1]} | {selectedModalCard["Rarity"]}</div>
                        {selectedModalCard['Effect'] !== '-' && selectedModalCard['Effect'] !== "" &&
                            <div className="cardInformationEffect"><span className="cardInformationLabel">Effect:</span> {selectedModalCard['Effect']}</div>
                        }
                        {selectedModalCard['Trigger'] &&
                            <div className="cardInformationEffect"> {selectedModalCard['Trigger']}</div>
                        }
                        <div className="cardBullets">
                            {selectedModalCard['Category'] && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Category:</span> {selectedModalCard['Category']}</li>
                                </ul>
                            )}
                            {selectedModalCard['Color'] && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Color:</span> {selectedModalCard['Color'] && selectedModalCard['Color'].join(', ')}</li>
                                </ul>
                            )}
                            {selectedModalCard['Type'] && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Type:</span> {selectedModalCard['Type'] && selectedModalCard['Type'].join(', ')}</li>
                                </ul>
                            )}
                            {selectedModalCard['Cost'] && selectedModalCard['Category'] !== 'Leader' && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Cost:</span> {selectedModalCard['Cost'] && selectedModalCard['Cost']['Generic']}</li>
                                </ul>
                            )}
                            {selectedModalCard['Power'] > 0 && (selectedModalCard['Category'] !== 'Event' || selectedModalCard['Category'] !== 'Stage') && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Power:</span> {selectedModalCard['Power']}</li>
                                </ul>
                            )}
                            {selectedModalCard['Counter'] > 0 && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Combo:</span> {selectedModalCard['Counter']}</li>
                                </ul>
                            )}
                        </div>
                    </div>
                );
            case "digimon":
                return (
                    <div className='cardInformation'>
                        <div className="cardInformationName">{selectedModalCard['Card Name']}</div>
                        <div className="cardInformationNumber">{selectedModalCard["Set"]}</div>
                        <div className="cardInformationNumber">{selectedModalCard['CARD ID'][0]}-{selectedModalCard['CARD ID'][1]} | {selectedModalCard["Rarity"]}</div>
                        {selectedModalCard['Effect'] !== '-' && selectedModalCard['Effect'] !== "" &&
                            <div className="cardInformationEffect"><span className="cardInformationLabel">Effect:</span> {selectedModalCard['Effect']}</div>
                        }
                        {selectedModalCard['Inherited Effect'] !== '-' && selectedModalCard['Inherited Effect'] !== "" &&
                            <div className="cardInformationEffect"><span className="cardInformationLabel">Inherited Effect:</span> {selectedModalCard['Inherited Effect']}</div>
                        }
                        {selectedModalCard['Security Effect'] !== '-' && selectedModalCard['Security Effect'] !== "" &&
                            <div className="cardInformationEffect"><span className="cardInformationLabel">Security Effect:</span> {selectedModalCard['Security Effect']}</div>
                        }
                        {selectedModalCard['Trigger'] &&
                            <div className="cardInformationEffect"> {selectedModalCard['Trigger']}</div>
                        }
                        <div className="cardBullets">
                            {selectedModalCard['Level'] && selectedModalCard['Level'] !== '-' && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Level:</span> {selectedModalCard['Level']}</li>
                                </ul>
                            )}
                            {selectedModalCard['Cost'] && selectedModalCard['Category'] !== 'Leader' && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Cost:</span> {selectedModalCard['Cost'] && selectedModalCard['Cost']['Generic']}</li>
                                </ul>
                            )}
                            {Object.keys(selectedModalCard['Evolve Cost']).length !== 0 && selectedModalCard['Category'] !== 'Digi-Egg' && (
                                <ul>
                                    <li className="cardInformationBullet">
                                        <span className="cardInformationLabel">Evolve Cost:</span> {Object.entries(selectedModalCard['Evolve Cost']).map(([key, value]) => (
                                            <span key={key}>{value} from {key}</span>
                                        )).reduce((prev, curr) => [prev, ', ', curr])}
                                    </li>
                                </ul>
                            )}
                            {selectedModalCard['Power'] > 0 && (selectedModalCard['Category'] !== 'Event' || selectedModalCard['Category'] !== 'Stage') && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Power:</span> {selectedModalCard['Power']}</li>
                                </ul>
                            )}
                            {selectedModalCard['Counter'] > 0 && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Counter:</span> {selectedModalCard['Counter']}</li>
                                </ul>
                            )}
                            {selectedModalCard['Category'] && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Category:</span> {selectedModalCard['Category']}</li>
                                </ul>
                            )}
                            {selectedModalCard['Color'] && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Color:</span> {selectedModalCard['Color'] && selectedModalCard['Color'].join(', ')}</li>
                                </ul>
                            )}
                            {selectedModalCard['Type'] && selectedModalCard['Type'][0] !== "-" && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Type:</span> {selectedModalCard['Type'] && selectedModalCard['Type'].join(', ')}</li>
                                </ul>
                            )}
                            {selectedModalCard['Attribute'] && selectedModalCard['Attribute'] !== "-" && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Attribute:</span> {selectedModalCard['Attribute']}</li>
                                </ul>
                            )}
                            {selectedModalCard['Form'] && selectedModalCard['Form'] !== "-" && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Form:</span> {selectedModalCard['Form']}</li>
                                </ul>
                            )}
                        </div>
                    </div>
                );
            case "unionarena":
                return (
                    <div className='cardInformation'>
                        <div className="cardInformationName">{selectedModalCard['Card Name']}</div>
                        <div className="cardInformationNumber">{selectedModalCard["Set"]}</div>
                        <div className="cardInformationNumber">{selectedModalCard['Key']} | {selectedModalCard["Rarity"]} | {selectedModalCard["Series"]}</div>
                        {selectedModalCard['Effect'] !== '-' && selectedModalCard['Effect'] !== "" &&
                            <div className="cardInformationEffect"><span className="cardInformationLabel">Effect:</span> {selectedModalCard['Effect']}</div>
                        }
                        {selectedModalCard['Trigger'] &&
                            selectedModalCard['Trigger'] !== "" &&
                            <div className="cardInformationEffect"><span className="cardInformationLabel">Trigger: </span>[{selectedModalCard['Trigger Type']}] {selectedModalCard['Trigger']}</div>
                        }
                        {selectedModalCard['Raid'] &&
                            selectedModalCard['Raid'] !== "" &&
                            <div className="cardInformationEffect"><span className="cardInformationLabel">Raid: </span>{selectedModalCard['Raid']}</div>
                        }
                        <div className="cardBullets">
                            {selectedModalCard['Category'] && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Category:</span> {selectedModalCard['Category']}</li>
                                </ul>
                            )}
                            {selectedModalCard['Color'] && selectedModalCard['Color'].length !== 0 && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Color:</span> {selectedModalCard['Color'] && selectedModalCard['Color'].join(', ')}</li>
                                </ul>
                            )}
                            {selectedModalCard['Type'].length !== 0 && selectedModalCard['Type'] && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Type:</span> {selectedModalCard['Type'] && selectedModalCard['Type'].join(', ')}</li>
                                </ul>
                            )}
                            {selectedModalCard['Cost'] && selectedModalCard['Category'] !== 'Leader' && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Cost:</span> {selectedModalCard['Cost'] && selectedModalCard['Cost']['Generic']}</li>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Action Cost:</span> {selectedModalCard['Cost'] && selectedModalCard['Cost']['Action Cost']}</li>
                                    {selectedModalCard['Cost']['Energy Generation'] > 0 && (
                                        <li className="cardInformationBullet"><span className="cardInformationLabel">Energy Generation:</span> {selectedModalCard['Cost'] && selectedModalCard['Cost']['Energy Generation']}</li>
                                    )}

                                </ul>
                            )}
                            {selectedModalCard['Power'] > 0 && (selectedModalCard['Category'] !== 'Event' || selectedModalCard['Category'] !== 'Site') && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Battle Power:</span> {selectedModalCard['Power']}</li>
                                </ul>
                            )}
                        </div>
                    </div>
                );
            case "gundam":
                return (
                    <div className='cardInformation'>
                        <div className="cardInformationName">{selectedModalCard['Card Name']}</div>
                        <div className="cardInformationNumber">{selectedModalCard["Set"]}</div>
                        <div className="cardInformationNumber">{selectedModalCard['Key']} | {selectedModalCard["Rarity"]} | {selectedModalCard["Series"]}</div>
                        {selectedModalCard['Effect'] !== '-' && selectedModalCard['Effect'] !== "" &&
                            <div className="cardInformationEffect"><span className="cardInformationLabel">Effect:</span> {selectedModalCard['Effect']}</div>
                        }
                        {selectedModalCard['Burst'] &&
                            selectedModalCard['Burst'] !== "" &&
                            <div className="cardInformationEffect"><span className="cardInformationLabel">Burst: </span>{selectedModalCard['Burst']}</div>
                        }
                        <div className="cardBullets">
                            {selectedModalCard['Category'] && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Category:</span> {selectedModalCard['Category']}</li>
                                </ul>
                            )}
                            {selectedModalCard['Color'] && selectedModalCard['Color'].length !== 0 && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Color:</span> {selectedModalCard['Color'] && selectedModalCard['Color'].join(', ')}</li>
                                </ul>
                            )}
                            {selectedModalCard['Link Requirement'] && selectedModalCard['Link Requirement'].length !== 0 && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Link Requirement:</span> {selectedModalCard['Link Requirement']}</li>
                                </ul>
                            )}
                            {selectedModalCard['Type'].length !== 0 && selectedModalCard['Type'] && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Type:</span> {selectedModalCard['Type'] && selectedModalCard['Type'].join(', ')}</li>
                                </ul>
                            )}
                            {selectedModalCard['Locations'].length > 0 && selectedModalCard['Locations'] && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Location:</span> {selectedModalCard['Locations'] && selectedModalCard['Locations'].join(', ')}</li>
                                </ul>
                            )}
                            {selectedModalCard['Cost'] && selectedModalCard['Category'] !== 'Leader' && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Cost:</span> {selectedModalCard['Cost'] && selectedModalCard['Cost']['Generic']}</li>
                                </ul>
                            )}
                            {selectedModalCard['Level'] >= 0 && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Lvl:</span> {selectedModalCard['Level']}</li>
                                </ul>
                            )}
                            {selectedModalCard['AP'] >= 0 && (selectedModalCard['Category'] !== 'Command') && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">AP:</span> {selectedModalCard['AP']}</li>
                                </ul>
                            )}
                            {selectedModalCard['HP'] >= 0 && (selectedModalCard['Category'] !== 'Command') && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">HP:</span> {selectedModalCard['HP']}</li>
                                </ul>
                            )}
                        </div>
                    </div>
                );
            case "projectk":
                return (
                    <div className='cardInformation'>
                        <div className="cardInformationName">{selectedModalCard['Card Name']}</div>
                        <div className="cardInformationNumber">{selectedModalCard['Key']} | {selectedModalCard["Rarity"]} | {selectedModalCard["Series"]}</div>
                        {selectedModalCard['Effect'] !== '-' && selectedModalCard['Effect'] !== "" &&
                            <div className="cardInformationEffect"><span className="cardInformationLabel">Effect:</span> {selectedModalCard['Effect']}</div>
                        }
                        <div className="cardBullets">
                            {selectedModalCard['Category'] && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Category:</span> {selectedModalCard['Category']}</li>
                                </ul>
                            )}
                            {selectedModalCard['Color'] && selectedModalCard['Color'].length !== 0 && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Color:</span> {selectedModalCard['Color'] && selectedModalCard['Color'].join(', ')}</li>
                                </ul>
                            )}
                            {selectedModalCard['Type'].length !== 0 && selectedModalCard['Type'] && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Type:</span> {selectedModalCard['Type'] && selectedModalCard['Type'].join(', ')}</li>
                                </ul>
                            )}
                            {selectedModalCard['Cost'] && selectedModalCard['Category'] !== 'Battlefield' && selectedModalCard['Category'] !== 'Legend' && selectedModalCard['Category'] !== 'Rune' && (
                                <ul>
                                    <li className="cardInformationBullet">
                                        <span className="cardInformationLabel">Cost: </span>
                                        {selectedModalCard['Cost']['Generic']}
                                    </li>
                                    {Object.entries(selectedModalCard['Cost']).map(([key, value]) =>
                                        key !== 'Generic' && value && value !== '0' && (
                                            <li key={key} className="cardInformationBullet">
                                                <span className="cardInformationLabel">Power Cost:</span> {value} {key}
                                            </li>
                                        )
                                    )}
                                </ul>
                            )}
                            {selectedModalCard['Might'] >= 0 && (selectedModalCard['Might'] !== null) && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Might:</span> {selectedModalCard['Might']}</li>
                                </ul>
                            )}
                            {selectedModalCard['Restriction'] !== null && (
                                <ul>
                                    <li className="cardInformationBullet"><span className="cardInformationLabel">Subtitle:</span> {selectedModalCard['Restriction']}</li>
                                </ul>
                            )}
                        </div>
                    </div>
                )
            default:
                return null;
        }
    };

    const renderUniqueButton = () => {
        switch (selectedCardType) {
            case "fusionworld":
                if ((selectedModalCard['Key'].endswith('-F') || selectedModalCard['Key'].endswith('-B'))) {
                    return (
                        <IconButton
                            style={{ width: '25px', height: '25px', position: 'absolute', top: '85px', right: '0px' }}
                            className="addOne"
                            onClick={() => handleFlipButton(selectedModalCard)}
                        >
                            <SvgIcon sx={{ fontSize: 'inherit' }}>
                                <svg
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                >
                                    <path d="M21 9H7.5C5.01472 9 3 11.0147 3 13.5C3 15.9853 5.01472 18 7.5 18H12M21 9L17 5M21 9L17 13" />
                                </svg>
                            </SvgIcon>
                        </IconButton>
                    );
                }
                return null;
            default:
                return null;
        }
    };

    const handleToggleView = () => {
        setViewMode(prevMode => {
            if (isSmallScreen) {
                switch (prevMode) {
                    case 'card-only':
                        return 'text-only';
                    case 'text-only':
                        return 'card-only';
                    default:
                        return 'card-only';
                }
            }
            else {
                switch (prevMode) {
                    case 'card-and-text':
                        return 'card-only';
                    case 'card-only':
                        return 'text-only';
                    case 'text-only':
                        return 'card-and-text';
                    default:
                        return 'card-and-text';
                }
            }
        });
    };

    const renderCardContent = () => {
        switch (viewMode) {
            case 'card-only':
                return (
                    <div className="custom-modal-image">
                        <img
                            src={`/card_images/${selectedCardType}/${selectedModalCard.ArtSrc ? `${selectedModalCard.ArtSrc}.webp` : `${selectedModalCard.Key}.webp`}`}
                            alt={selectedModalCard['Card Name']}
                            loading="lazy"
                        />
                    </div>
                );
            case 'text-only':
                return renderCardInformation();
            case 'card-and-text':
            default:
                return (
                    <>
                        <div className="custom-modal-image">
                            <img
                                src={`/card_images/${selectedCardType}/${selectedModalCard.ArtSrc ? `${selectedModalCard.ArtSrc}.webp` : `${selectedModalCard.Key}.webp`}`}
                                alt={selectedModalCard['Card Name']}
                                loading="lazy"
                            />
                        </div>
                        {renderCardInformation()}
                    </>
                );
        }
    };

    return (
        selectedModalCard && (
            <Dialog open={showModal} onClose={handleClose} maxWidth="md" fullWidth style={{ backgroundColor: "#000000e6" }}>
                <DialogTitle>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon style={{ color: 'white' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="custom-modal-content">
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handlePreviousCard}
                            aria-label="previous"
                            sx={{ position: 'absolute', left: 8, top: '50%' }}
                        >
                            <ArrowBackIosIcon style={{ color: 'white' }} />
                        </IconButton>
                        {renderCardContent()}
                        {(isSmallScreen &&
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={handleToggleView}
                                aria-label="toggle-view"
                                sx={{ position: 'absolute', top: 8, right: '50%' }}
                            >
                                <SvgIcon sx={{ fontSize: 'inherit' }}>
                                    <svg
                                        fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor">
                                        <path d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" fill="#0D0D0D" /><path d="M21.894 11.553C19.736 7.236 15.904 5 12 5c-3.903 0-7.736 2.236-9.894 6.553a1 1 0 0 0 0 .894C4.264 16.764 8.096 19 12 19c3.903 0 7.736-2.236 9.894-6.553a1 1 0 0 0 0-.894zM12 17c-2.969 0-6.002-1.62-7.87-5C5.998 8.62 9.03 7 12 7c2.969 0 6.002 1.62 7.87 5-1.868 3.38-4.901 5-7.87 5z" fill="#0D0D0D" />
                                    </svg>
                                </SvgIcon>
                            </IconButton>
                        )}
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleNextCard}
                            aria-label="next"
                            sx={{ position: 'absolute', right: 8, top: '50%' }}
                        >
                            <ArrowForwardIos style={{ color: 'white' }} />
                        </IconButton>
                    </div>
                </DialogContent>
            </Dialog>
        )
    );
};

export default CardModal;
