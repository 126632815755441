export const decodeQueryStringToDeck = (queryString, cardType, cardData) => {
    const cardInfoArray = queryString.split(',');

    // Use card keys as identifiers
    const decodedDeck = cardInfoArray.map((cardInfo) => {
        const [key, count] = cardInfo.split(':');
        const foundCard = cardData && cardData.find(
            (card) => card['Key'] === key
        );

        if (foundCard) {
            return { ...foundCard, count: parseInt(count, 10) || 1 };
        } else {
            return null;
        }
    });

    console.log('Decoded Deck:', decodedDeck);
    return Array.from(new Set(decodedDeck.filter(Boolean))); // Remove duplicates and filter out undefined values

};

// Save a specific deck
export const saveDeck = (deckId, deck, selectedCardType, deckName) => {
    try {
        const allDecks = loadAllDecks(); // Get all decks from storage
        allDecks[deckId] = {
            deckName,
            selectedCardType,
            cards: deck,
        }; // Save the deck with its type
        localStorage.setItem('decks', JSON.stringify(allDecks)); // Save back to storage
        console.log(`Deck ${deckId} saved with type: ${selectedCardType}`);
    } catch (error) {
        console.error('Error saving deck:', error);
    }
};


export const loadDeck = (deckId) => {
    const allDecks = loadAllDecks();
    const savedDeck = allDecks[deckId];
    return savedDeck || null; // Return the deck object or null if not found
};


// Load all decks
export const loadAllDecks = () => {
    try {
        const savedDecks = localStorage.getItem('decks');
        return savedDecks ? JSON.parse(savedDecks) : {};
    } catch (error) {
        console.error('Error loading all decks:', error);
        return {};
    }
};

// Delete a specific deck
export const deleteDeck = (deckId) => {
    try {
        const allDecks = loadAllDecks();
        delete allDecks[deckId];
        localStorage.setItem('decks', JSON.stringify(allDecks));
        console.log(`Deck ${deckId} deleted!`);
    } catch (error) {
        console.error('Error deleting deck:', error);
    }
};


export const generateUniqueDeckId = () => {
    const allDecks = loadAllDecks(); // Load all existing decks
    let deckId;
    do {
        deckId = Math.random().toString(36).substring(2, 10); // Generate a random ID
    } while (allDecks.hasOwnProperty(deckId)); // Repeat if ID is taken
    return deckId;
};

export const saveNewDeck = (deck, selectedCardType, deckName) => {
    const deckId = generateUniqueDeckId();
    saveDeck(deckId, deck, selectedCardType, deckName);
    console.log(`Deck saved with ID: ${deckId}`);
    return deckId; // Return the ID if needed
};

