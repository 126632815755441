import React, { useEffect, useState } from 'react';
import './DeckBuilder.css';
import PDFModal from './PDFModal'; // Import your PDF modal component
import SvgIcon from '@mui/material/SvgIcon';
import { Image } from '@react-pdf/renderer';
import BarGraph from './BarGraph';
import Stack from '@mui/material/Stack';
import DeckList from './DeckList.js'
import AdComponent from './AdComponent.js';
import TestHands from './TestHands.js';
import { Select, MenuItem, Checkbox, ListItemText, Button, Menu } from '@mui/material';

const DeckBuilder = ({ selectedCards, removeFromSelectedCards, handleClearDeckBuilder, addToSelectedCards, openModal, cardData, sortedSelectedCards, setSortedSelectedCards, handleImportButtonClick, handleExportButtonClick, isExportPopupOpen, activeSection, toggleSection, handleCopyToClipboard, exportedLink, formatCardExportText, copiedToClipboard, isImportPopupOpen, setImportedDecklist, handleDecklistSubmit, closePopup, setSelectedCardType, handleFullScreen, ZoomMinus, ZoomPlus, zoomLevel, isFullScreen, selectedCardType, handleOpenPDFViewer, handleClosePDFModal, isPDFModalOpen, handleEnter, isDeckBuilderVisible, isSmallScreen, handleFlipButton, isSmallerScreen, handleSaveDeck }) => {

    const excludedCategories = ['leader', 'digi-egg', 'legend'];

    const handleRemoveOne = (card) => {
        removeFromSelectedCards(card, 1);
    };

    const handleOpenPDFClick = () => {
        handleSettingsClose();

        setTimeout(() => {
            handleOpenPDFViewer();
        }, 100); // 200 milliseconds delay
    };

    useEffect(() => {
        const digiEggCounts = {};

        // Count the total number of Digi-Egg cards and track the counts of each individual Digi-Egg card
        selectedCards.forEach(card => {
            if ((card['Category'] || '').toLowerCase() === 'digi-egg') {
                const cardKey = card['Key']; // Assuming 'Key' holds the unique identifier for each card
                digiEggCounts[cardKey] = card['count'] || 1; // Initialize count to 1 if not provided
            }
        });

        // Calculate the total count of Digi-Egg cards
        const totalDigiEggCount = Object.values(digiEggCounts).reduce((total, count) => total + count, 0);

        // If the total count exceeds 5, alert the user and prevent further processing
        if (totalDigiEggCount > 5) {
            alert("You can't add more than 5 Digi-Egg cards in total!");
            return;
        }
    }, [selectedCards, sortedSelectedCards]);

    // Calculate total number of cards in the deck
    const totalCards = selectedCards.reduce((total, card) => {
        if ((card["Category"].toLowerCase() !== 'leader') && (card["Category"].toLowerCase() !== 'digi-egg')) {
            return total + card.count;
        }
        return total;
    }, 0);
    // Calculate the count for each rarity
    const categoryCount = selectedCards.reduce((counts, card) => {
        counts[card.Category] = (counts[card.Category] || 0) + card.count;
        return counts;
    }, {});
    // Calculate the count for each rarity
    const costCount = selectedCards.reduce((counts, card) => {
        const cost = card.Cost.Generic; // Assuming 'Cost' object contains 'Generic' property
        const count = card.count; // Assuming 'count' property holds the number of duplicates
        const costKey = `${cost} Cost`; // Adding "Cost " prefix to cost
        if (card["Category"].toLowerCase() !== 'leader') {
            counts[costKey] = (counts[costKey] || 0) + count;
        }
        return counts;
    }, {});

    // Convert levelCount object to an array of key-value pairs
    const costCountArray = Object.entries(costCount);

    // Sort the array based on levels (the first element of each pair)
    costCountArray.sort((a, b) => {
        // Extract the level from the key (remove "Level " prefix)
        const levelA = parseInt(a[0].replace("Cost ", ""));
        const levelB = parseInt(b[0].replace("Cost ", ""));
        return levelA - levelB;
    });

    // Convert the sorted array back to an object
    const sortedCostCount = Object.fromEntries(costCountArray);

    const levelCount = selectedCards.reduce((counts, card) => {
        const level = card.Level;
        const count = card.count; // Assuming 'count' property holds the number of duplicates
        const levelKey = `Level ${level}`; // Adding "Level " prefix to level
        if (level > 0) {
            counts[levelKey] = (counts[levelKey] || 0) + count;
        }
        return counts;
    }, {});

    // Convert levelCount object to an array of key-value pairs
    const levelCountArray = Object.entries(levelCount);

    // Sort the array based on levels (the first element of each pair)
    levelCountArray.sort((a, b) => {
        // Extract the level from the key (remove "Level " prefix)
        const levelA = parseInt(a[0].replace("Level ", ""));
        const levelB = parseInt(b[0].replace("Level ", ""));
        return levelA - levelB;
    });    // Convert the sorted array back to an object
    const sortedLevelCount = Object.fromEntries(levelCountArray);

    const counterCounts = selectedCards.reduce((acc, card) => {
        // Ignore cards where "Category" is equal to "Leader"
        if (card.Category === "Leader") {
            return acc;
        }

        const counterValue = card.Counter;
        const count = card.count || 1; // Use card.count if it exists, otherwise default to 1
        acc[counterValue] = (acc[counterValue] || 0) + count;
        return acc;
    }, {});



    const cardTypesCount = selectedCards.reduce((counts, card) => {
        // Assuming card['Type'] contains the type of the card
        card['Type'].forEach(type => {
            counts[type] = (counts[type] || 0) + card.count;
        });
        return counts;
    }, {});

    const handleClear = () => {
        if (typeof handleClearDeckBuilder === 'function') {
            handleClearDeckBuilder();
        }
    };

    const handleRightClick = (e, card) => {
        e.preventDefault();

        if (card.isBackImage) {
            // Handle right-click for back image
            const backImageKey = card['Key'].replace('-F', '-B');
            const matchingBackCard = cardData.find((c) => c['Key'] === backImageKey);

            if (matchingBackCard) {
                // Do something with the matching back card
                openModal(matchingBackCard);
            } else {
                // Handle the case when there is no matching back card
                console.log('No matching back card');
            }
        } else {
            // Handle right-click for front image
            openModal(card);
            console.log(card);
        }
    };

    const menuHandler = (type) => {
        if (type === 'zoomin' || type === 'zoomout') {
            if (type === 'zoomin') {
                ZoomPlus(['clImage', 'clcircleButtons', 'cltopButtons', 'clleftButtons', 'clfontSize', 'clsvgSize']);
            }
            else if (type === 'zoomout') {
                ZoomMinus(['clImage', 'clcircleButtons', 'cltopButtons', 'clleftButtons', 'clfontSize', 'clsvgSize']);
            }
        }
        else {
            handleSettingsClose();

            setTimeout(() => {
                switch (type) {
                    case 'pdf':
                        handleOpenPDFViewer();
                        break;
                    case 'view':
                        handleEnter('cardList');
                        break;
                    case 'save':
                        handleSaveDeck(sortedSelectedCards, selectedCardType);
                        break;
                    case 'import':
                        handleImportButtonClick();
                        break;
                    case 'export':
                        handleExportButtonClick();
                        break;
                    case 'delete':
                        handleClearDeckBuilder();
                        break;
                }

            }, 100); // 200 milliseconds delay
        }
    };


    const [settingsAnchorE1, setSettingsAnchorE1] = useState(null);


    const handleSettingsClick = (event) => {
        setSettingsAnchorE1(event.currentTarget);
    };

    const handleSettingsClose = () => {
        setSettingsAnchorE1(null);
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if ((isExportPopupOpen || isImportPopupOpen) && !event.target.closest('.popup')) {
                closePopup();
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isExportPopupOpen, isImportPopupOpen]);




    return (
        <>
            {selectedCardType && (
                <div className="deck-builder">
                    <div className="sticky-bar-db">
                        <div className="container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {!isSmallerScreen && (
                                    <>
                                        <Button className="filterButton" onClick={() => menuHandler('import')}>Import</Button>
                                        <Button className="filterButton" onClick={() => menuHandler('export')}>Export</Button>
                                    </>
                                )}
                                <Button className="filterButton" onClick={() => menuHandler('view')}>View Cards</Button>
                                <Button className="filterButton" onClick={() => menuHandler('delete')}>Clear</Button>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', height: '50px' }}>

                                <img onClick={() => menuHandler('view')} src='assets/deck2.png' className='settingsLogo' alt="egmanlogo" />                                <TestHands cards={selectedCards} selectedCardType={selectedCardType}></TestHands>

                                <img
                                    src='assets/settings.svg'
                                    className='settingsLogo'
                                    alt="egmanlogo"
                                    onClick={handleSettingsClick} // Open the menu on click

                                />

                                <Menu
                                    anchorEl={settingsAnchorE1}
                                    open={Boolean(settingsAnchorE1)}
                                    onClose={handleSettingsClose} // Close the menu when clicked outside or Escape key is pressed
                                >
                                    <MenuItem onClick={() => menuHandler('view')}>View Cards</MenuItem>
                                    <MenuItem onClick={() => menuHandler('save')}>Save Deck</MenuItem>
                                    <MenuItem onClick={() => menuHandler('import')}>Import</MenuItem>
                                    <MenuItem onClick={() => menuHandler('export')}>Export</MenuItem>
                                    <MenuItem onClick={() => menuHandler('zoomin')}>Zoom In</MenuItem>
                                    <MenuItem onClick={() => menuHandler('zoomout')}>Zoom Out</MenuItem>
                                    {!isSmallScreen ? (
                                        <MenuItem onClick={() => menuHandler('pdf')}>Proxies</MenuItem>
                                    ) : null}
                                    <MenuItem onClick={() => menuHandler('delete')}>Clear Deck</MenuItem>
                                </Menu>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="deck">
                            <div className="deck-stats">
                                <div className="leader-card-container">
                                    {sortedSelectedCards && sortedSelectedCards.some((card) => (card["Category"] || "").toLowerCase() === "leader" || (card["Category"] || "").toLowerCase() === "digi-egg" || card["Category"].toLowerCase() === "legend") ? (
                                        <div className="leader-sub-container">
                                            {sortedSelectedCards
                                                .filter((card) => (card["Category"] || "").toLowerCase() === "leader" || (card["Category"] || "").toLowerCase() === "digi-egg" || card["Category"].toLowerCase() === "legend")
                                                .map((leaderCard) => (
                                                    <div key={leaderCard['Key']} className="leader-card" onContextMenu={(e) => handleRightClick(e, leaderCard)}>
                                                        <img
                                                            src={`/card_images/${selectedCardType}/${leaderCard.ArtSrc ? `${leaderCard.ArtSrc}.webp` : `${leaderCard.Key}.webp`}`}
                                                            alt={leaderCard['Card Name']}
                                                            loading="lazy"
                                                            className='card-image-container'
                                                            style={{ width: `200px` }}
                                                        />
                                                        <div style={{ width: `32px`, height: `32px`, top: `27px`, left: `170px`, fontSize: `1em` }} className={leaderCard.count > 4 ? 'cardCount exceeded-limit' : 'cardCount'}>
                                                            {leaderCard.count}
                                                        </div>
                                                        <div style={{ top: `80px`, left: `170px`, fontSize: `2em` }} className="addOne" onClick={() => handleRemoveOne(leaderCard)}>
                                                            <SvgIcon sx={{ fontSize: 'inherit' }}>
                                                                <svg
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth={1.5}
                                                                    stroke="currentColor"
                                                                >
                                                                    <path d="M7 12h10"></path>
                                                                </svg>
                                                            </SvgIcon>
                                                        </div>
                                                        <div style={{ top: `125px`, left: `170px`, fontSize: `2em` }} className="addOne viewModal" onClick={(e) => handleRightClick(e, leaderCard)}>
                                                            <SvgIcon sx={{ fontSize: 'inherit' }}>
                                                                <svg
                                                                    fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor">
                                                                    <path d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" fill="#0D0D0D" /><path d="M21.894 11.553C19.736 7.236 15.904 5 12 5c-3.903 0-7.736 2.236-9.894 6.553a1 1 0 0 0 0 .894C4.264 16.764 8.096 19 12 19c3.903 0 7.736-2.236 9.894-6.553a1 1 0 0 0 0-.894zM12 17c-2.969 0-6.002-1.62-7.87-5C5.998 8.62 9.03 7 12 7c2.969 0 6.002 1.62 7.87 5-1.868 3.38-4.901 5-7.87 5z" fill="#0D0D0D" />
                                                                </svg>
                                                            </SvgIcon>
                                                        </div>
                                                        {(leaderCard['Key'].endsWith('-F') || leaderCard['Key'].endsWith('-B')) && (
                                                            <div style={{ top: `187px`, left: `170px`, fontSize: `2em` }} className="addOne" onClick={() => handleFlipButton(leaderCard)}>
                                                                <SvgIcon sx={{ fontSize: 'inherit' }}>
                                                                    <svg
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth={1.5}
                                                                        stroke="currentColor"
                                                                    >
                                                                        <path d="M21 9H7.5C5.01472 9 3 11.0147 3 13.5C3 15.9853 5.01472 18 7.5 18H12M21 9L17 5M21 9L17 13" />
                                                                    </svg>
                                                                </SvgIcon>
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                        </div>
                                    ) : (
                                        <div className="leader-sub-container">
                                        </div>
                                    )}
                                </div>

                                <div className="right-side-container">
                                    <div className="deckStatsList">
                                        <div className={`rarityList ${totalCards > (selectedCardType.toLowerCase() === 'fusionworld' ? 60 : (selectedCardType.toLowerCase() === 'optcg' || 'digimon' || 'unionarena' ? 50 : 0)) ? "exceeded-limit" : ""} ${totalCards >= 30 && totalCards <= 49 ? "highlight-yellow" : ""}`}>
                                            <div className={"rarityTitle"}>Total</div>
                                            <div className={"rarityCount"}>{totalCards}</div>
                                        </div>
                                        {Object.entries(categoryCount).map(([rarity, count]) => {
                                            // Conditionally modify the rarity
                                            if (rarity === 'Digi-Egg') {
                                                rarity = "Egg";
                                            }

                                            // Render the JSX
                                            return (
                                                <div key={rarity} className="rarityList">
                                                    <div title={rarity} className="rarityTitle">{rarity.substring(0, 1)}</div>
                                                    <div className="rarityCount">{count}</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {(selectedCardType === 'optcg' || selectedCardType === 'fusionworld' || selectedCardType === 'unionarena' || selectedCardType === 'gundam') && (
                                        <div className="cardTypesList">
                                            {Object.entries(cardTypesCount).map(([type, count]) => (
                                                <div key={type} className="rarityList">
                                                    <div className="rarityTitle">{type}</div>
                                                    <div className="rarityCount">{count}</div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    {(selectedCardType === 'digimon' && (
                                        <div className="barGraph">
                                            <BarGraph title={"Level"} levelCount={sortedLevelCount} />
                                        </div>
                                    ))}
                                    {(selectedCardType === 'fusionworld' && (
                                        <div className="barGraphModal">
                                            <BarGraph title={"Cost"} levelCount={sortedCostCount} />
                                            <BarGraph title={"Combo"} levelCount={counterCounts} />
                                        </div>

                                    ))}
                                    {(selectedCardType === 'optcg' && (
                                        <div className="barGraphModal">
                                            <BarGraph title={"Cost"} levelCount={sortedCostCount} />
                                            <BarGraph title={"Counter"} levelCount={counterCounts} />
                                        </div>

                                    ))}
                                    {(selectedCardType === 'unionarena' && (
                                        <div className="barGraphModal">
                                            <BarGraph title={"Cost"} levelCount={sortedCostCount} />
                                        </div>

                                    ))}
                                </div>
                            </div>
                            <div>
                                <div className="selected-cards-container">
                                    {sortedSelectedCards
                                        .filter(card => !excludedCategories.includes(card['Category'].toLowerCase()))
                                        .map((card) => (
                                            <div className="deckBuilderList" onContextMenu={(e) => handleRightClick(e, card)}>
                                                <div className='cardbuttons'>
                                                    <div style={{ width: `${(zoomLevel.clcircleButtons)}px`, height: `${(zoomLevel.clcircleButtons)}px`, top: `${(zoomLevel.cltopButtons)}px`, left: `${(zoomLevel.clleftButtons)}px`, fontSize: `${zoomLevel.clfontSize}px` }} className={card.count > 4 ? 'cardCount exceeded-limit' : 'cardCount'}>
                                                        {card.count}
                                                    </div>
                                                    <div style={{ width: `${(zoomLevel.clcircleButtons)}px`, height: `${(zoomLevel.clcircleButtons)}px`, top: `${(zoomLevel.cltopButtons * 3)}px`, left: `${(zoomLevel.clleftButtons)}px`, fontSize: `${zoomLevel.clsvgSize}em` }} className="addOne" onClick={() => addToSelectedCards(card)}>
                                                        <SvgIcon sx={{ fontSize: 'inherit' }}>
                                                            <svg
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth={1.5}
                                                                stroke="currentColor"
                                                            >
                                                                <path d="M7 12h10m-5-5v10"></path>
                                                            </svg>
                                                        </SvgIcon>
                                                    </div>
                                                    <div style={{ top: `${(zoomLevel.cltopButtons * 5)}px`, left: `${(zoomLevel.clleftButtons)}px`, fontSize: `${zoomLevel.clsvgSize}em` }} className="addOne" onClick={() => handleRemoveOne(card)}>
                                                        <SvgIcon sx={{ fontSize: 'inherit' }}>
                                                            <svg
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth={1.5}
                                                                stroke="currentColor"
                                                            >
                                                                <path d="M7 12h10"></path>
                                                            </svg>
                                                        </SvgIcon>
                                                    </div>

                                                    <div style={{ top: `${(zoomLevel.cltopButtons * 7)}px`, left: `${(zoomLevel.clleftButtons)}px`, fontSize: `${zoomLevel.clsvgSize}em` }} className="addOne viewModal" onClick={(e) => handleRightClick(e, card)}>
                                                        <SvgIcon sx={{ fontSize: 'inherit' }}>
                                                            <svg
                                                                fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor">
                                                                <path d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" fill="#0D0D0D" /><path d="M21.894 11.553C19.736 7.236 15.904 5 12 5c-3.903 0-7.736 2.236-9.894 6.553a1 1 0 0 0 0 .894C4.264 16.764 8.096 19 12 19c3.903 0 7.736-2.236 9.894-6.553a1 1 0 0 0 0-.894zM12 17c-2.969 0-6.002-1.62-7.87-5C5.998 8.62 9.03 7 12 7c2.969 0 6.002 1.62 7.87 5-1.868 3.38-4.901 5-7.87 5z" fill="#0D0D0D" />

                                                            </svg>
                                                        </SvgIcon>
                                                    </div>

                                                </div>
                                                <div>
                                                    <img
                                                        src={`/card_images/${selectedCardType}/${card.ArtSrc ? `${card.ArtSrc}.webp` : `${card.Key}.webp`}`}
                                                        alt={card['Card Name']}
                                                        loading="lazy"
                                                        className='card-image-container'
                                                        style={{ width: `${zoomLevel.clImage}px` }}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                </div>
                                <AdComponent slotId="0c8f1c7b-7f84-4b04-be07-e41ad7359b94" type="display" />
                            </div>
                        </div>
                    </div>
                </div >
            )}
        </>
    );
};

export default DeckBuilder;
